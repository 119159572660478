.layout {
  background: linear-gradient(330deg, rgba(225, 225, 225, 0.05) 0%, rgba(225, 225, 225, 0.05) 33.333%, rgba(114, 114, 114, 0.05) 33.333%, rgba(114, 114, 114, 0.05) 66.666%, rgba(52, 52, 52, 0.05) 66.666%, rgba(52, 52, 52, 0.05) 99.999%), linear-gradient(66deg, rgba(181, 181, 181, 0.05) 0%, rgba(181, 181, 181, 0.05) 33.333%, rgba(27, 27, 27, 0.05) 33.333%, rgba(27, 27, 27, 0.05) 66.666%, rgba(251, 251, 251, 0.05) 66.666%, rgba(251, 251, 251, 0.05) 99.999%), linear-gradient(225deg, rgba(98, 98, 98, 0.05) 0%, rgba(98, 98, 98, 0.05) 33.333%, rgba(222, 222, 222, 0.05) 33.333%, rgba(222, 222, 222, 0.05) 66.666%, rgba(228, 228, 228, 0.05) 66.666%, rgba(228, 228, 228, 0.05) 99.999%), linear-gradient(90deg, rgb(28, 20, 63), rgb(40, 160, 253));
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 0 50px;
  overflow: hidden;
  height: 100vh;
}

.layout:after {
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: hsla(0,0,0,0.4);
  pointer-events: none;
}

.logo {
  position: relative;
  z-index: 10;

  text-shadow: 0 1px 0 #999, 0 2px 0 #888, 0 3px 0 #777, 0 4px 0 #666, 0 5px 0 #555, 0 6px 0 #444, 0 7px 0 #333, 0 8px 7px #001135;
  color: #ffffff;
  font-size: 3em;
  font-family: "Arial Black", "Verdana font-weight:bold", sans-serif;
  font-weight: 900;

  cursor: pointer;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome and Opera */
}

.subheader {
  font-size: 1.5em;
}

.layout {
  width: auto;
}

.centerPanel {
  display: grid;
  height: 600px;
  justify-content: center;
  align-content: center;
}

.panelForgot {
    grid-template-columns: 350px;
    grid-template-rows: 250px;
}

.panelLogin {
  grid-template-columns: 350px;
  grid-template-rows: 330px;
}

.panelResetPassword {
  grid-template-columns: 400px;
  grid-template-rows: 300px;
}

.panelRegistration {
  grid-template-columns: 750px;
  //grid-template-rows: 520px;
}

.iconNotFound {
  font-size: 72px;
  margin-top: 5px;
}

.panelNotFound {
  grid-template-columns: 670px;
  grid-template-rows: 130px;
}

.panel {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 2px;
  background: #f7f7f7;
  padding: 24px;
  z-index: 10;
  position: relative;
}


@media screen and (min-height: 0px) and (max-height: 900px) {
  .footerLogo {
    display: none;
  }
  /* hide logos on small screens */

  .iconNotFound {
    display: none;
  }

  .panelNotFound {
    grid-template-columns: 250px;
    grid-template-rows: 220px;
  }
}

@media screen and (min-height: 901px) {
  .footerLogo {
    display: block;
  }
  /* show logos otherwise */
}

.footerLogo {
  z-index: 9;
  position: absolute;
  width: 665px;
  left: 0;
  right: 0;
  bottom: 50px;
  margin-left: auto;
  margin-right: auto;
}


.footerLogo a {
  opacity: 0.5;
  filter: alpha(opacity=50); /* For IE8 and earlier */
  margin: 0 40px 0 0;;
}

.footerLogo a:last-child {
  margin: 0;
}

.footerLogo a:hover {
  opacity: 0.8;
  filter: alpha(opacity=80); /* For IE8 and earlier */
}

@media screen and (max-width: 600px) {
  .returnToConferenceSite {
    display: none;
  }
  /* hide on small screens */
}

.returnToConferenceSite {
  z-index: 10;
  position: absolute;
  color: #ffffff;
  bottom: 5px;
  left: 10px;

  a {
    color: #fff;
    font-weight: 400;
  }

  a:hover {
    font-weight: 600;
  }

  a:before {
    content: url('/back.png'); /* with class ModalCarrot ??*/
    left: -5px;
    top: 3px;
    position: relative;
  }
}
